<template>
  <v-card elevation="6" class="my-1">
    <v-card-title class="text-uppercase">
      <v-spacer />
      {{ $t("invoices." + type) }}
      <v-spacer />
    </v-card-title>
    <div class="close">
      <v-btn
        @click="select(null)"
        elevation="0"
        v-if="user_id"
        height="30px"
        class="mt-2 mr-2"
      >
        {{ $t("invoices.clear") }}
      </v-btn>
    </div>
    <form action :data-vv-scope="`user-${type}-form`" @submit.prevent="save">
      <v-card-text>
        <v-row justify="center" v-if="!user_id">
          <v-col cols="8" md="4">
            <base-border-card
              :title="$store.state.auth.user.studio.studio_name"
              @card_clicked="select('me')"
              :two_lines="true"
            >
            </base-border-card>
          </v-col>
          <v-col cols="8" md="4">
            <base-border-card
              :title="$t('invoices.existing')"
              @card_clicked="select('existing')"
              :two_lines="true"
            >
            </base-border-card>
          </v-col>
          <v-col cols="8" md="4">
            <base-border-card
              :title="$t('invoices.new')"
              @card_clicked="select('new')"
              :two_lines="true"
            >
            </base-border-card>
          </v-col>
        </v-row>
        <v-row v-else-if="existing_type && user_id == 'existing'">
          <v-col>
            <label>{{ $tc("invoices.users." + existing_type) }}</label>
            <v-autocomplete
              outlined
              dense
              :items="items"
              :search-input.sync="searchCustomer"
              :loading="isLoadingCustomer"
              v-model="user_id"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-else-if="user_id == 'existing'">
          <v-col cols="6" class="py-1">
            <base-border-card
              :title="$t('artist')"
              @card_clicked="selectExisting('tattooer')"
            >
            </base-border-card>
          </v-col>
          <v-col cols="6" class="py-1">
            <base-border-card
              :title="$tc('customer', 1)"
              @card_clicked="selectExisting('customer')"
            >
            </base-border-card>
          </v-col>
          <v-col cols="6" class="py-1">
            <base-border-card
              :title="$t('inventorys.supplier')"
              @card_clicked="selectExisting('supplier')"
            >
            </base-border-card>
          </v-col>
          <v-col cols="6" class="py-1">
            <base-border-card
              :title="$t('expenses.other')"
              @card_clicked="selectExisting('others')"
            >
            </base-border-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="py-0">
            <label>Nombre de la empresa</label>
            <v-text-field
              outlined
              dense
              hide-details
              :disabled="user_id != 'new'"
              v-model="user.name"
              data-vv-validate-on="blur"
              v-validate="'required'"
              :error-messages="errors.collect(`user-${type}-form.name`)"
              data-vv-name="name"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <label>Dirección</label>
            <v-text-field
              outlined
              dense
              hide-details
              :disabled="user_id != 'new'"
              v-model="user.address"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <label>Código postal</label>
            <v-text-field
              outlined
              dense
              hide-details
              :disabled="user_id != 'new'"
              v-model="user.postal_code"
            />
          </v-col>

          <v-col cols="6" class="py-0">
            <label>Ciudad</label>
            <v-text-field
              outlined
              dense
              hide-details
              :disabled="user_id != 'new'"
              v-model="user.city"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <label>País</label>
            <v-text-field
              outlined
              dense
              hide-details
              :disabled="user_id != 'new'"
              v-model="user.country"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <label>CIF</label>
            <v-text-field
              outlined
              dense
              hide-details
              :disabled="user_id != 'new'"
              v-model="user.dni"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </form>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "UserSelector",
  props: {
    invoice: {
      type: Object,
      default: () => ({ items: [], taxes: [] }),
    },
    type: {
      type: String,
      default: () => "from",
    },
  },
  watch: {
    searchCustomer(val) {
      //console.log(val);
      if (this.existing_type == "customer") this.fetchCustomers();
    },
  },
  methods: {
    async validate() {
      if (!this.user_id) return false;
      return await this.$validator.validateAll(`user-${this.type}-form`);
    },
    select(user) {
      switch (user) {
        case "me":
          user = this.my_user;
          break;
        case "existing":
          break;
        case "new":
          this.invoice[this.user_new_camp] = {};
          break;
      }
      this.existing_type = null;
      this.user_id = user;
    },
    selectExisting(type) {
      console.log("a");
      switch (type) {
        case "tattooer":
          this.fetchTattooers();
          break;
        case "customer":
          this.fetchCustomer();
          break;
        case "supplier":
          this.fetchSupplier();
          break;
        case "others":
          this.fetchOthers();
          break;
      }
    },
    ...mapActions("studios", ["getStudioTattooersByDate"]),
    fetchTattooers() {
      this.getStudioTattooersByDate({
        studio_id: this.$store.state.auth.user.studio.id,
      }).then((data) => {
        this.items = data.map((t) => {
          return {
            text: t.tattooer.user.fullname,
            value: t.tattooer_id,
          };
        });
        this.items_complete = data.map((t) => t.tattooer);
        this.existing_type = "tattooer";
      });
    },
    ...mapActions("customers", ["getCustomers"]),
    fetchCustomer() {
      console.log("load", this.isLoadingCustomer);
      //if (this.isLoadingCustomer) return;
      this.isLoadingCustomer = true;
      let filters = {
        itemsPerPage: 20,
        search: this.searchCustomer,
      };
      if (this.$store.getters["auth/isStudio"])
        filters.studio_id = this.$store.state.auth.user.studio.id;
      if (this.$store.getters["auth/isTattooer"])
        filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
      this.existing_type = "customer";

      this.getCustomers({
        filters,
        pagination: {},
        loading: false,
      }).then((response) => {
        this.items = response.data.map((c) => {
          return {
            text: c.user.fullname,
            value: c.id,
          };
        });
        this.items_complete = response.data;
        this.isLoadingCustomer = false;
      });
    },
    ...mapActions("supplier", ["getAll"]),

    fetchSupplier() {
      this.getAll().then((data) => {
        this.items = data.map((s) => {
          return {
            text: s.supplier_name,
            value: s.id,
          };
        });
        this.items_complete = data;

        //console.log(this.suppliers);
        this.existing_type = "supplier";
      });
    },
    fetchOthers() {
      this.existing_type = "others";
    },
  },
  computed: {
    user_id: {
      get() {
        return this.invoice[this.user_id_camp];
      },
      set(value) {
        this.$set(this.invoice, this.user_id_camp, value);
        //this.invoice[this.user_id_camp] = value;
      },
    },
    user_id_camp() {
      switch (this.type) {
        case "from":
          return "from_user_id";
        case "to":
          return "to_user_id";
      }
    },
    user_new_camp() {
      switch (this.type) {
        case "from":
          return "new_from_user";
        case "to":
          return "new_to_user";
      }
    },
    my_user() {
      return this.$store.state.auth.user.studio.id;
    },
    user: {
      get() {
        switch (this.user_id) {
          case this.my_user:
            let user = this.$store.state.auth.user;
            return {
              name: user.studio.studio_name,
              address: user.studio.address,
              postal_code: user.studio.postal_code,
              city: user.studio.city,
              country: user.studio.country,
              dni: user.studio.dni,
            };
          case "new":
            return this.invoice[this.user_new_camp];
        }

        if (this.invoice[this.type]) {
          return {
            name: this.invoice[this.type].fullname,
            address: this.invoice[this.type].address,
            postal_code: this.invoice[this.type].postal_code,
            city: this.invoice[this.type].city,
            country: this.invoice[this.type].country,
            dni: this.invoice[this.type].dni,
          };
        }

        let u = this.items_complete.find((i) => i.id == this.user_id);
        switch (this.existing_type) {
          case "tattooer":
            return {
              name: u.user.fullname,
              address: u.user.address,
              postal_code: u.user.postal_code,
              city: u.user.city,
              country: u.nationality,
              dni: u.DNI,
            };
          case "customer":
            return {
              name: u.user.fullname,
              address: "",
              postal_code: "",
              city: "",
              country: "",
              dni: "",
            };
          case "supplier":
            return {
              name: u.supplier_name,
              address: u.direction_fiscal,
              postal_code: u.postal_cody,
              city: u.city,
              country: u.country,
              dni: u.num_fiscal,
            };
          case "others":
            return {
              name: u.user.fullname,
              address: u.address,
              postal_code: u.postal_code,
              city: u.city,
              country: u.country,
              dni: u.dni,
            };
        }
        return {};
      },
      set(value) {
        if (this.user_id == "new") {
          this.invoice[this.user_new_camp] = value;
        }
      },
    },
  },
  data() {
    return {
      existing_type: null,
      searchCustomer: "",
      items: [],
      items_complete: [],
    };
  },
};
</script>

<style scoped lang="sass">

#border-card
  &.v-card__title
    @media (max-width: 960px )
      font-size: 14px !important


label
  line-height: 1 !important
</style>
